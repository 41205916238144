<template>
  <div class="container" fluid="sm">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 p-0">
            <div class="d-flex justify-content-start align-items-center px-2 py-4">
              <div>
                <div class="progress-circle-25">
                  <h4 class="progress-circle-text">1/4</h4>
                </div>
              </div>
              <div class="ml-2">
                <h4 class="font-weight-bold">{{ $t('personal_detail') }}</h4>
                <h6>{{ $t('next_step') }}: {{ $t('employment') }}</h6>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <b-form @submit.prevent="submitClickEvent($event)">
              <FormRenderer
                v-if="formData"
                ref="personal-detail"
                v-model="formInputData"
                :form-configuration="formData"
              ></FormRenderer>

              <div v-else class="d-flex justify-content-center">
                <h6 class="text-danger">{{ error_message }}</h6>
              </div>
            </b-form>
          </div>
        </div>
        <loading :is-busy="isBusy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import { FormRenderer } from 'v-form-builder'
import FormService from '@/api/service/FormService'
import AccountService from '@/api/service/AccountService'
import Loading from '@/components/Loading.vue'

import '@/assets/scss/v-form-builder.scss'

import formDataMixin from '@/mixins/formDataMixin'

import { bus } from '@/main.js'

export default {
  components: { FormRenderer, Loading },
  mixins: [formDataMixin],
  data() {
    return {
      formData: null,
      formInputData: null,
      getInputData: null,
      isBusy: false,
      first_merge: true,
      error_message: null,
      prefixPhoneNumber: '',
    }
  },
  watch: {
    formInputData: {
      handler: function () {
        while (this.first_merge) {
          this.formInputData = this.getInputData
          if (this.getInputData) {
            this.formInputData.mobile_number = this.prefixPhoneNumber
          } else {
            this.formInputData = { mobile_number: this.prefixPhoneNumber }
          }
          this.first_merge = false
        }
      },
      deep: true, // object model need to use deep watch
    },
  },
  beforeCreate() {
    this.$formEvent.$off()
    this.$formEvent.$on()
  },
  created() {
    this.getUserProfile()
    this.$formEvent.$on('confirm', (value) => {
      console.log(value)
      this.saveInputData()
    })
    bus.$on('uploadDocument', (data) => {
      if (data === 'uploaded') {
        this.getDocumentList()
      }
    })
  },

  methods: {
    submitClickEvent(evt) {
      evt.preventDefault()
      document.getElementById('control_button_submit').submit()
    },
    async getFormData() {
      this.isBusy = true
      try {
        const res = await FormService.getFormData()
        this.isBusy = false
        if (res.status == 200) {
          const formResponseData = res.data.data
          if (formResponseData.input_data) {
            this.getInputData = JSON.parse(formResponseData.input_data.input_data)
          }
          if (formResponseData.pages) {
            formResponseData.pages.forEach((page) => {
              if (page.type === 'personal') {
                this.formData = this.bindLocale(JSON.parse(page.form_data))
                this.version_id = page.version_id

                this.getDocumentList()
              }
            })
          }
          /**
           * After finish api call, added javascript click event
           */
          this.addClickEvent()
          this.removeZero()
        }
      } catch (err) {
        this.isBusy = false
        this.error_message = err
        this.showAlert(err)
      }
    },

    async getDocumentList() {
      try {
        this.isBusy = true
        const resDocument = await FormService.getDocumentFormData({
          version_id: this.version_id,
        })

        this.isBusy = false

        if (resDocument.status == 200) {
          const uploadedResponse = resDocument.data.data
          this.$store.dispatch('form/saveUploadedDocument', {
            uploadedDocumentList: uploadedResponse,
          })
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },

    async saveInputData() {
      this.isBusy = true
      try {
        const res = await FormService.saveFormData({
          version_id: this.version_id,
          input_data: JSON.stringify(this.formInputData),
          type: 'personal',
        })

        this.isBusy = false

        if (res.status == 200) {
          this.$store.dispatch('auth/setLoanFormState', {
            loanFormState: 'personal',
          })
          this.$router.replace({ path: 'employment' })
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getUserProfile() {
      try {
        this.isBusy = true
        const res = await AccountService.getUserProfile()
        this.isBusy = false
        if (res.status == 200) {
          this.getFormData()
          const profileResponse = res.data.data
          this.prefixPhoneNumber = profileResponse.phone_number
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
  },
}
</script>

<style></style>
